<template>
  <div>
    <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="100px">
      <el-form-item label="当前手机号码">
        <el-input disabled v-model="passwordForm.phoneNo"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="验证码">
        <el-input placeholder="请输入验证码" autocomplete="new-password" v-model="passwordForm.code">
          <template #append>
            <el-link type="primary" href="javascript:void(0)" :disabled="!isCodeEnable" @click="startCountdown">
              获取验证码
            </el-link>
            <vue-countdown :time="time" v-slot="{ seconds }" v-if="counting" :autoStart="true"
                           @end="onCountdownEnd">
              （{{ seconds }} 秒）
            </vue-countdown>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" label="新密码">
        <el-input placeholder="新密码，8-18位数字、字母和特殊字符组合" maxlength="18" @onfocus="this.type='password'" autocomplete="new-password" show-password v-model="passwordForm.password"></el-input>
      </el-form-item>
      <el-form-item prop="confirmPass" label="确认密码">
        <el-input placeholder="请再次输入密码" show-password maxlength="18" v-model="passwordForm.confirmPass"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="display: flex; justify-content: center;">
          <el-button :loading="loading" type="primary" @click="handleConfirm" style="width: 120px;">保存</el-button>
          <el-button @click="handleCancel" style="width: 120px;">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'change-password',
  props: ['phone_No'],
  components: { VueCountdown },
  data () {
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        const patrn = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,18}$/
        if (!patrn.exec(value)) {
          return callback(new Error('新密码必须字母、数字、字符任意两种组成，密码长度最短8位'))
        }
        if (this.passwordForm.confirmPass !== '') {
          this.$refs.passwordForm.validateField('confirmPass')
        }
        callback()
      }
    }
    var validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      passwordForm: {
        phoneNo: this.phone_No,
        code: '',
        password: '',
        confirmPass: ''
      },
      passwordRules: {
        code: [
          {
            required: true,
            message: '请输入手机验证码！',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'change'
          },
          {
            min: 8,
            max: 18,
            message: '长度在 8 到 18 个字符',
            trigger: 'blur'
          }
        ],
        confirmPass: [
          {
            required: true,
            validator: validateConfirmPass,
            trigger: 'change'
          }
        ]
      },
      time: 60 * 1000,
      counting: false, // 倒计时
      isPassing: false, // 滑动验证是否通过
      isCodeEnable: true, // 验证码获取功能是否可用
      loading: false
    }
  },
  methods: {
    handleConfirm () {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            dateBackgroundUrl: '/bid/user/reset', // ^^^
            dateBackgroundRequestType: 'post',
            data: {
              mobile: this.passwordForm.phoneNo,
              password: this.passwordForm.password,
              verification: this.passwordForm.code
            }
          }
          forwardBID(data)
            .then(result => {
              if (result.code === 0) {
                this.loading = false
                ElMessage.success('密码修改成功！')
                this.$store.dispatch('logout')
                this.$router.push({ name: 'Login' })
              } else if (result.code === 1) {
                ElMessage.error(result.msg)
                this.loading = false
              }
            })
            .catch(error => {
              this.loading = false
              console.log(error)
            })
        }
      })
    },
    handleCancel () {
      this.passwordForm.code = ''
      this.passwordForm.password = ''
      this.passwordForm.confirmPass = ''
      this.$refs.passwordForm.resetFields()
      this.closePasswordEmit()
    },
    getVerifyCode () {
      console.log('this.passwordForm.phoneNo: ' + this.passwordForm.phoneNo)
      const data = {
        dateBackgroundUrl: '/bid/verifyCode/registergetVerifyCode',
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.passwordForm.phoneNo,
          verifyTypeCode: '31'
        }
      }
      forwardBID(data)
        .then(result => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    onCountdownEnd () {
      this.time = 60 * 1000
      // this.isPassing = false
      this.counting = false
      this.isCodeEnable = true
    },
    startCountdown () {
      this.isCodeEnable = false
      this.counting = true
      this.getVerifyCode()
    },
    passCallback () {
      this.isCodeEnable = true
    }
  },
  setup (props, { emit }) {
    function closePasswordEmit () {
      console.log('子组件：')
      emit('closePasswordEmit', 'change-password')
    }
    return {
      closePasswordEmit
    }
  }
}
</script>

<style scoped>
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
input:-webkit-autofill {
 transition: background-color 5000s ease-in-out 0s;
}

</style>
