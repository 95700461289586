<template>
  <div>
    <el-form :model="emailForm" :rules="emailRules" ref="emailForm" label-width="120px">
      <el-form-item prop="email" label="新邮箱">
        <el-input v-model="emailForm.email"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="display: flex; justify-content: center;">
          <el-button :loading="loading" type="primary" @click="handleConfirm" style="width: 120px;">保存</el-button>
          <el-button @click="handleCancel" style="width: 120px;">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'

export default {
  name: 'change-email',
  data () {
    var validateEmail = (rule, value, callback) => {
      const regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!regex.test(value)) {
        callback(new Error('请输入正确的电子邮箱！'))
      } else {
        callback()
      }
    }
    return {
      currentUserInfo: null,
      emailForm: {
        email: ''
      },
      emailRules: {
        email: [{
          required: true,
          message: '请输入电子邮箱',
          trigger: 'change'
        },
        {
          required: true,
          validator: validateEmail,
          trigger: 'change'
        }]
      },
      loading: false
    }
  },
  mounted () {
    // this.currentUserInfo = JSON.parse(this.userInfo)
    // this.emailForm.email = this.currentUserInfo.enterpriseContactEmail
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  methods: {
    handleConfirm () {
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            dateBackgroundUrl: '/bid/user/updateUser?enterpriseContactMobilephone=' + this.currentUserInfo.enterpriseContactMobilephone +
              '&enterpriseContactEmail=' + this.currentUserInfo.enterpriseContactEmail + '&realName=' + this.currentUserInfo.realName, // ^^^
            dateBackgroundRequestType: 'post',
            data: {
              enterpriseContactMobilephone: this.currentUserInfo.enterpriseContactMobilephone,
              enterpriseContactEmail: this.emailForm.email,
              realName: this.currentUserInfo.realName
            }
          }
          forwardBID(data)
            .then(result => {
              if (result.code === 0) {
                this.loading = false
                ElMessage.success({ message: '电子邮箱修改成功！', offset: 20 })
                this.closeEmailEmit()
                this.currentUserInfo.enterpriseContactEmail = this.emailForm.email
                this.$store.dispatch('setUserInfo', JSON.stringify(this.currentUserInfo))
              } else {
                this.loading = false
                ElMessage.error(result.msg)
              }
            })
            .catch(error => {
              this.loading = false
              console.log(error)
            })
        }
      })
    },
    handleCancel () {
      this.emailForm.email = ''
      this.$refs.emailForm.resetFields()
      this.closeEmailEmit()
    },
    opened () {
      this.currentUserInfo = JSON.parse(this.userInfo)
      this.emailForm.email = this.currentUserInfo.enterpriseContactEmail
    }
  },
  setup (props, { emit }) {
    function closeEmailEmit () {
      console.log('子组件：')
      emit('closeEmailEmit', 'email')
    }
    return {
      closeEmailEmit
    }
  }
}
</script>

<style scoped>

</style>
