<template>
  <div>
    <el-form :model="realnameForm" :rules="realnameRules" ref="realnameForm" label-width="120px">
      <el-form-item prop="realname" label="真实姓名">
        <el-input v-model="realnameForm.realname"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="display: flex; justify-content: center;">
          <el-button :loading="loading" type="primary" @click="handleConfirm" style="width: 120px;">保存</el-button>
          <el-button @click="handleCancel" style="width: 120px;">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'

export default {
  name: 'change-realname',
  data () {
    return {
      currentUserInfo: null,
      realnameForm: {
        realname: ''
      },
      realnameRules: {
        realname: [{
          required: true,
          message: '请输入用户名',
          trigger: 'change'
        },
        {
          min: 1,
          max: 20,
          message: '请输入 1 到 20 位字符',
          trigger: 'blur'
        }]
      },
      loading: false
    }
  },
  // watch: {
  //   '$store.getters.userInfo': {
  //     deep: true,
  //     handler: function (newValue, oldValue) {
  //       if (newValue) {
  //         const user = JSON.parse(newValue)
  //         this.userInfo = user
  //         this.realnameForm.realName = user.realName ? user.realName : user.mobile
  //         console.log('this.realnameForm.realName:')
  //         console.log(this.realnameForm.realName)
  //       } else {
  //         this.realnameForm.realName = ''
  //       }
  //     }
  //   }
  // },
  mounted () {
    // this.currentUserInfo = JSON.parse(this.userInfo)
    // this.realnameForm.realname = this.currentUserInfo.realName ? this.currentUserInfo.realName : this.currentUserInfo.mobile
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  methods: {
    handleConfirm () {
      this.$refs.realnameForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            dateBackgroundUrl: '/bid/user/updateUser?enterpriseContactMobilephone=' + this.currentUserInfo.enterpriseContactMobilephone +
              '&enterpriseContactEmail=' + this.currentUserInfo.enterpriseContactEmail + '&realName=' + this.realnameForm.realName, // ^^^
            dateBackgroundRequestType: 'post',
            data: {
              enterpriseContactMobilephone: this.currentUserInfo.enterpriseContactMobilephone,
              enterpriseContactEmail: this.currentUserInfo.enterpriseContactEmail,
              realName: this.realnameForm.realname
            }
          }
          forwardBID(data)
            .then(result => {
              if (result.code === 0) {
                this.loading = false
                ElMessage.success({ message: '真实姓名修改成功！', offset: 20 })
                this.currentUserInfo.realName = this.realnameForm.realname
                this.$store.dispatch('setUserInfo', JSON.stringify(this.currentUserInfo))
                this.closeRealnameEmit()
              } else {
                this.loading = false
                ElMessage.error(result.msg)
              }
            })
            .catch(error => {
              this.loading = false
              console.log(error)
            })
        }
      })
    },
    handleCancel () {
      this.realnameForm.realname = ''
      this.$refs.realnameForm.resetFields()
      this.closeRealnameEmit()
    },
    opened () {
      this.currentUserInfo = JSON.parse(this.userInfo)
      this.realnameForm.realname = this.currentUserInfo.realName ? this.currentUserInfo.realName : this.currentUserInfo.mobile
    }
  },
  setup (props, { emit }) {
    function closeRealnameEmit () {
      console.log('子组件：')
      emit('closeRealnameEmit', 'realname')
    }
    return {
      closeRealnameEmit
    }
  }
}
</script>

<style scoped>

</style>
