<template>
  <div>
    <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm" label-width="120px">
      <el-form-item prop="phoneNo" label="新手机号码">
        <el-input v-model="phoneForm.phoneNo"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="验证码">
        <el-input placeholder="请输入验证码" v-model="phoneForm.code">
          <template #append>
            <el-link type="primary" href="javascript:void(0)" :disabled="!isCodeEnable" @click="startCountdown">
              获取验证码
            </el-link>
            <vue-countdown :time="time" v-slot="{ seconds }" v-if="counting" :autoStart="true"
                           @end="onCountdownEnd">
              （{{ seconds }} 秒）
            </vue-countdown>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div style="display: flex; justify-content: center;">
          <el-button :loading="loading" type="primary" @click="handleConfirm" style="width: 120px;">保存</el-button>
          <el-button @click="handleCancel" style="width: 120px;">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'
import { updateMobile } from '@/api/user.js'
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'change-phone',
  components: { VueCountdown },
  data () {
    var validatePhoneNo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空！'))
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(value)) {
        callback(new Error('请输入正确的手机号码！'))
      } else {
        callback()
      }
    }
    return {
      userInfos: JSON.parse(this.$store.getters.userInfo),
      phoneForm: {
        phoneNo: '',
        code: ''
      },
      phoneRules: {
        phoneNo: [
          {
            required: true,
            validator: validatePhoneNo,
            trigger: 'change'
          }
        ],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        }]
      },
      time: 60 * 1000,
      counting: false, // 倒计时
      isPassing: false, // 滑动验证是否通过
      isCodeEnable: true, // 验证码获取功能是否可用
      loading: false
    }
  },
  methods: {
    handleConfirm () {
      const vm = this
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            // dateBackgroundUrl: '/bid/user/updateMobile?mobile=' + this.phoneForm.phoneNo + '&verification=' + this.phoneForm.code, // ^^^
            // dateBackgroundRequestType: 'post',
            newMobile: vm.phoneForm.phoneNo,
            verification: vm.phoneForm.code,
            oldMobile: vm.userInfos.mobile
          }
          updateMobile(data)
            .then(result => {
              if (result.code === 0) {
                vm.loading = false
                ElMessage.success({ message: '手机号修改成功！', offset: 20 })
                vm.userInfos.mobile = vm.phoneForm.phoneNo
                vm.$store.dispatch('setUserInfo', JSON.stringify(vm.userInfos))
                vm.closePhoneEmit()
              } else {
                vm.loading = false
                ElMessage.error(result.msg)
              }
            })
            .catch(error => {
              vm.loading = false
              console.log(error)
            })
        }
      })
    },
    handleCancel () {
      this.phoneForm.phoneNo = ''
      this.phoneForm.code = ''
      this.$refs.phoneForm.resetFields()
      this.closePhoneEmit()
    },
    getVerifyCode () {
      const data = {
        dateBackgroundUrl: '/bid/verifyCode/registergetVerifyCode',
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.phoneForm.phoneNo,
          verifyTypeCode: '32'
        }
      }
      forwardBID(data)
        .then(result => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    onCountdownEnd () {
      this.time = 60 * 1000
      // this.isPassing = false
      this.counting = false
      this.isCodeEnable = true
    },
    startCountdown () {
      console.log('this.phoneForm.phoneNo: ' + this.phoneForm.phoneNo)
      const data = {
        dateBackgroundUrl: '/bid/user/checkmobile?mobile=' + this.phoneForm.phoneNo,
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.phoneForm.phoneNo
        }
      }
      forwardBID(data).then(result => {
        if (result.code === 0) {
          this.isCodeEnable = false
          this.counting = true
          this.getVerifyCode()
        } else {
          ElMessage.error({ message: result.msg, offset: 20 })
        }
      }).catch(error => {
        console.log(error)
        ElMessage.error({ message: error, offset: 20 })
      })
    },
    passCallback () {
      this.isCodeEnable = true
    }
  },
  setup (props, { emit }) {
    function closePhoneEmit () {
      console.log('子组件：')
      emit('closePhoneEmit', 'change-phone')
    }
    return {
      closePhoneEmit
    }
  }
}
</script>

<style scoped>

</style>
